<template>
  <div class="detection_list_wrap">
    <!--        <div @click="movePage('/detection/request')">의뢰하기</div>-->
    <table class="dh_table" width="100%" border="0" cellspacing="0" cellpadding="0"
           style="table-layout: auto; width: 1200px; table-layout: fixed;">
      <colgroup>
        <col width="96px"/>
        <col width="232px"/>
        <col width="455px"/>
        <col width="110px"/>
        <col width="154px"/>
        <col width="155px"/>
      </colgroup>
      <tr class="dh_header">
        <td colspan="1">{{ $t('en_no') }}</td>
        <td colspan="1">{{ $t('detection_domain1') }}</td>
        <td colspan="1">{{ $t('url') }}</td>
        <td colspan="1">{{ $t('status') }}</td>
        <td colspan="1">{{ $t('detection_date') }}</td>
        <td colspan="1">{{ $t('except_domain') }}</td>
      </tr>
    </table>
    <el-collapse accordion class="detection_item" @change="collapseChange($event)">
      <el-collapse-item :name="detect.dr_idx" v-for="(detect, index) in detectionData" :key="`detect${detect.dr_idx}`"
                        :disabled="clickIgnore">
        <template slot="title">
          <div class="no_count">{{ tableIdx(index) }}</div>
          <div class="detection_domain">{{ detect.dr_domain }}</div>
          <div class="detection_url">{{ detect.dr_page }}</div>
          <!--                    //검출상태 0-사용허가/ 1-내 이미지 아님 / 2-무시 / 3-보류 / 4-의뢰중-->
          <!--            <div class="detection_status permission" v-if="selectCollapse === '1'">셀렉트로 변경</div>-->
          <el-select class="select_status"
                     popper-class="category_select"
                     :popper-append-to-body="false"
                     v-if="selectCollapse === detect.dr_idx && !returnDomainStatus(detect.dr_status).noChange"
                     v-model="detect.dr_status_select" :placeholder="$t('select')" @change="changeStatus(detect)">
            <el-option v-for="(status) in detectionDomainStatus" :key="`setStatus${status.value}`"
                       v-if="!status.noChange" :value="status.value" :label="`${$t(status.name)}`"></el-option>
            <!--                        <el-option :value="'noImg'" :label="`${$t('no_myImg')}`"></el-option>
                                    <el-option :value="'ignore'" :label="`${$t('ignore')}`"></el-option>
                                    <el-option :value="'hold'" :label="`${$t('hold')}`"></el-option>-->
            <el-option :value="'request'" :label="`${$t('request1')}`"></el-option>
          </el-select>
          <div class="detection_status" :class="returnDomainStatus(detect.dr_status).class" v-else>{{ returnDomainStatusName(detect.dr_status) }}</div>
          <div class="detection_date">{{ returnDateFormat(detect.created_at, 'YYYY.MM.DD') }}</div>
          <button class="domain_except">
            <span :class="{'no_line' : detect.dr_except ===  1}"
                  @click="ignoreDomain(detect)">{{ detect.dr_except === 1 ? '-' : $t('except_domain') }}</span>
          </button>
        </template>
        <div class="detection_img my">
          <img :src="returnItemImage()" alt="">
          <div class="detection_img_bottom"></div>
          <div class="detection_img_text">{{ $t('myImg') }}</div>
        </div>
        <div class="detection_img">
          <img :src="returnDetectImage(detect)" alt="">
          <div class="detection_img_bottom"></div>
          <div class="detection_img_text">{{ $t('detection_img') }}</div>
        </div>
        <div class="detection_text_box">
          <div class="detection_text_title">{{ $t('d_img_title') }}</div>
          <div class="detection_text">{{ detect.dr_title }}</div>
          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
          <div class="detection_url cursor" @click="openUrl(detect.dr_page)">{{ detect.dr_page }}</div>
          <div class="detection_text_title">{{ $t('d_img_url') }}</div>
          <div class="detection_url cursor" @click="openUrl(detect.dr_image)">{{ detect.dr_image }}</div>
        </div>
      </el-collapse-item>
      <!--            <el-collapse-item name="2">
                      <template slot="title">
                          <div class="no_count">5</div>
                          <div class="detection_domain">https://www.chocolatebars.com</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-23445676185156156156161561</div>
                          <div class="detection_status noImg">{{ $t('no_myImg') }}</div>
                          <div class="detection_date">2021.05.29</div>
                          <div class="domain_except">
                              <span>{{ $t('except_domain') }}</span>
                          </div>
                      </template>
                      <div class="detection_img my">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('myImg') }}</div>
                      </div>
                      <div class="detection_img">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('detection_img') }}</div>
                      </div>
                      <div class="detection_text_box">
                          <div class="detection_text_title">{{ $t('d_img_title') }}</div>
                          <div class="detection_text">Open canned chocolate bars</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                      </div>
                  </el-collapse-item>
                  <el-collapse-item name="3">
                      <template slot="title">
                          <div class="no_count">4</div>
                          <div class="detection_domain">https://www.chocolatebars.com</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-23445676185156156156161561</div>
                          <div class="detection_status hold">{{ $t('hold') }}</div>
                          <div class="detection_date">2021.05.29</div>
                          <div class="domain_except">
                              <span>{{ $t('except_domain') }}</span>
                          </div>
                      </template>
                      <div class="detection_img my">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('myImg') }}</div>
                      </div>
                      <div class="detection_img">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('detection_img') }}</div>
                      </div>
                      <div class="detection_text_box">
                          <div class="detection_text_title">{{ $t('d_img_title') }}</div>
                          <div class="detection_text">Open canned chocolate bars</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                      </div>
                  </el-collapse-item>
                  <el-collapse-item name="4">
                      <template slot="title">
                          <div class="no_count">3</div>
                          <div class="detection_domain">https://www.chocolatebars.com</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-23445676185156156156161561</div>
                          <div class="detection_status ignore">{{ $t('ignore') }}</div>
                          <div class="detection_date">2021.05.29</div>
                          <div class="domain_except">
                              <span>{{ $t('except_domain') }}</span>
                          </div>
                      </template>
                      <div class="detection_img my">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('myImg') }}</div>
                      </div>
                      <div class="detection_img">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('detection_img') }}</div>
                      </div>
                      <div class="detection_text_box">
                          <div class="detection_text_title">{{ $t('d_img_title') }}</div>
                          <div class="detection_text">Open canned chocolate bars</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                      </div>
                  </el-collapse-item>
                  <el-collapse-item name="5">
                      <template slot="title">
                          <div class="no_count">2</div>
                          <div class="detection_domain">https://www.chocolatebars.com</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-23445676185156156156161561</div>
                          <div class="detection_status request">{{ $t('request1') }}</div>
                          <div class="detection_date">2021.05.29</div>
                          <div class="domain_except">
                              <span>{{ $t('except_domain') }}</span>
                          </div>
                      </template>
                      <div class="detection_img my">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('myImg') }}</div>
                      </div>
                      <div class="detection_img">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('detection_img') }}</div>
                      </div>
                      <div class="detection_text_box">
                          <div class="detection_text_title">{{ $t('d_img_title') }}</div>
                          <div class="detection_text">Open canned chocolate bars</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                      </div>
                  </el-collapse-item>
                  <el-collapse-item name="6">
                      <template slot="title">
                          <div class="no_count">1</div>
                          <div class="detection_domain">https://www.chocolatebars.com</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-23445676185156156156161561</div>
                          <div class="detection_status">-</div>
                          <div class="detection_date">2021.05.29</div>
                          <div class="domain_except">
                              <span>{{ $t('except_domain') }}</span>
                          </div>
                      </template>
                      <div class="detection_img my">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('myImg') }}</div>
                      </div>
                      <div class="detection_img">
                          <div class="detection_img_bottom"></div>
                          <div class="detection_img_text">{{ $t('detection_img') }}</div>
                      </div>
                      <div class="detection_text_box">
                          <div class="detection_text_title">{{ $t('d_img_title') }}</div>
                          <div class="detection_text">Open canned chocolate bars</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                          <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                          <div class="detection_url">https://www.chocolatebars/kr/canned-bars-234</div>
                      </div>
                  </el-collapse-item>-->
      <!--        <el-collapse-item name="2" class="header">-->
      <!--          <template slot="title">-->
      <!--            <div class="question">Q</div>-->
      <!--            <div class="list-title title">충전할 때 계좌 선택 단계에서 막혀요</div>-->
      <!--          </template>-->
      <!--          <div class="answer">A</div>-->
      <!--          <div class="answer_text">고객님이 등록한 계좌의 은행점검 시간에 충전을 시도하시면 계좌 선택이 안되는 경우가 있습니다. 점검시간 외에 시도하셨는데 선택이 안된다면 1:1 문의를 남겨주시기 바랍니다.-->
      <!--            <br><br> 감사합니다 :)-->
      <!--          </div>-->
      <!--        </el-collapse-item>-->
      <!--        <el-collapse-item name="3" class="header">-->
      <!--          <template slot="title">-->
      <!--            <div class="question">Q</div>-->
      <!--            <div class="list-title title">충전할 때 계좌 선택 단계에서 막혀요</div>-->
      <!--          </template>-->
      <!--          <div class="answer">A</div>-->
      <!--          <div class="answer_text">고객님이 등록한 계좌의 은행점검 시간에 충전을 시도하시면 계좌 선택이 안되는 경우가 있습니다. 점검시간 외에 시도하셨는데 선택이 안된다면 1:1 문의를 남겨주시기 바랍니다.-->
      <!--            <br><br> 감사합니다 :)-->
      <!--          </div>-->
      <!--        </el-collapse-item>-->
      <!--        <el-collapse-item name="4" class="header">-->
      <!--          <template slot="title">-->
      <!--            <div class="question">Q</div>-->
      <!--            <div class="list-title title">충전할 때 계좌 선택 단계에서 막혀요</div>-->
      <!--          </template>-->
      <!--          <div class="answer">A</div>-->
      <!--          <div class="answer_text">고객님이 등록한 계좌의 은행점검 시간에 충전을 시도하시면 계좌 선택이 안되는 경우가 있습니다. 점검시간 외에 시도하셨는데 선택이 안된다면 1:1 문의를 남겨주시기 바랍니다.-->
      <!--            <br><br> 감사합니다 :)-->
      <!--          </div>-->
      <!--        </el-collapse-item>-->
      <!--        <el-collapse-item name="5"class="header">-->
      <!--          <template slot="title">-->
      <!--            <div class="question">Q</div>-->
      <!--            <div class="list-title title">충전할 때 계좌 선택 단계에서 막혀요</div>-->
      <!--          </template>-->
      <!--          <div class="answer">A</div>-->
      <!--          <div class="answer_text">고객님이 등록한 계좌의 은행점검 시간에 충전을 시도하시면 계좌 선택이 안되는 경우가 있습니다. 점검시간 외에 시도하셨는데 선택이 안된다면 1:1 문의를 남겨주시기 바랍니다.-->
      <!--            <br><br> 감사합니다 :)-->
      <!--          </div>-->
      <!--        </el-collapse-item>-->
    </el-collapse>
  </div>
</template>

<script>
import {detectionDomainStatus} from "@/service/detectionService";
import dateMixins from "@/mixins/dateMixins";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
  name: "DetectionListDefaultLayout",
  mixins: [dateMixins, imageOption],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {
    detectionData: {default: () => []},
    itemData: {
      default: () => {
      }
    },
    paginationData: {default: {page: 1, size: 10, total: 0}},
  },
  data() {
    return {
      selectCollapse: '',
      searchOption: 'permission',
      detectionDomainStatus: detectionDomainStatus,

      clickIgnore: false,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
    collapseChange(e) {
      this.selectCollapse = e;
      let detectionIndex = this.detectionData.findIndex(d => d.dr_idx === e);
      if (detectionIndex > -1) {
        this.detectionData[detectionIndex].dr_status_select = this.detectionData[detectionIndex].dr_status;
      }
    },
    tableIdx(index) {
      return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
    },
    returnDomainStatus(value) {
      let index = detectionDomainStatus.findIndex(dds => dds.value == value);
      let returnStatus = {
        value: null,
        name: '-',
        noChange: false,
        class: '',
        description: '기본값',
      }
      if (index > -1) {
        returnStatus = detectionDomainStatus[index];
      }
      return returnStatus;
    },
    returnDomainStatusName(value) {
      let status = this.returnDomainStatus(value);
      if (status.name === '-') {
        return status.name;
      }
      return this.$t(status.name);
    },
    openUrl(url) {
      window.open(url, '_blank');
      //location.href= url;
    },
    returnDetectImage(detect) {
      if (util.isEmpty(detect.dr_image)) {
        return '';
      }
      return `${detect.dr_image}?${this.setImageOptions(271, 176, 'jpg')}`;
    },
    returnItemImage() {
      if (util.isEmpty(this.itemData) || util.isEmpty(this.itemData.ItemFile) || util.isEmpty(this.itemData.ItemFile.Item)) {
        return "";
      }
      return `${this.itemData.ItemFile.Item[0].org_url}?${this.setImageOptions(271, 176, 'png')}`;
    },
    changeStatus(detect) {
      if (detect.dr_status_select === 'request') {
        this.$router.push(`/detection/${detect.dr_idx}/request`);
        return false;
      }
      EventBus.$emit('detectListChangeStatus', detect);
    },
    ignoreDomain(detect) {
      this.clickIgnore = true;
      setTimeout(() => {
        this.clickIgnore = false;
      }, 500)

      EventBus.$emit('detectListIgnoreDomain', detect)
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
